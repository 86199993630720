.background {
  height: 100vh;
  width: 100%;
  display: flex;
  position: fixed;
  top: 0;
  z-index: -1;

  .left {
    background: #194b64 url(../assets/left.png) no-repeat
      right top;
    width: 50%;
    height: 100vh;
    margin-right: 1px;
  }

  .right {
    background: #805a29 url(../assets/right.png) no-repeat;
    right: 0;
    margin-right: -1px;
    width: 50%;
    height: 100vh;
  }

  @media (max-width: 1024px) {
    .left {
      width: 100%;
    }

    .right {
      width: 50px;
    }
  }
}

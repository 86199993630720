@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@100;200;300;400;500;600&display=swap");
@import "./src/globals/index.scss";

html,
body,
.App,
#root {
  height: 100%;
  font-family: "Source Sans Pro", "Open Sans", "Helvetica",
    sans-serif;

  @media (max-width: 1024px) {
    max-width: 100%;
  }
}

h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  color: white;
  font-weight: 100;
  font-size: 72px;
  text-transform: uppercase;
  font-family: "Source Sans Pro", "Open Sans", "Helvetica",
    sans-serif;
  text-shadow: 0.5px 0.5px rgb(73, 49, 3);
  word-wrap: normal;
  word-spacing: 40px;
  text-indent: -84px;

  @media (max-width: 1024px) {
    word-spacing: 0px;
    text-indent: 0px;
    text-align: left;
    line-height: 100%;
    padding: 0 5%;
    font-size: 52px;
    width: 100%;
  }

  @media (max-width: 374px) {
    font-size: 32px;
  }
}

header {
  @media (max-width: 1024px) {
    display: flex;
    padding-right: 14px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.portfolio-link {
  color: white;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  font-size: 20px;
  display: block;
  background: rgba(255,255,255, 0.1);
  text-indent: 56px;
  margin-top: 20px;

  &:hover {
    color: rgb(218, 218, 218);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import "../globals/index.scss";

.experience {
  max-width: 1024px;
  margin: 40px auto 0;
  padding: 0 30px;

  li {
    list-style-type: none;
    width: 100%;
    display: flex;
    color: white;
    margin-bottom: 50px;

    &:after {
      width: 16px;
      height: 16px;
      content: "";
      display: block;
      position: absolute;
      left: calc(50% - 8px);
      border-radius: 100%;
      background: #805a29;
      border: 1px solid white;
      margin-top: 15px;
      -webkit-animation: slide-top 0.4s
        cubic-bezier(0.645, 0.045, 0.355, 1) 1.2s both;
      animation: slide-top 0.4s
        cubic-bezier(0.645, 0.045, 0.355, 1) 1.2s both;
    }

    .left {
      width: 50%;
      text-align: right;
      padding-right: 40px;
      -webkit-animation: slide-left 0.5s
        cubic-bezier(0.645, 0.045, 0.355, 1) 1.4s both;
      animation: slide-left 0.5s
        cubic-bezier(0.645, 0.045, 0.355, 1) 1.4s both;

      .title {
        font-size: 32px;
        font-weight: 100;
      }

      .description {
        font-weight: 100;
        margin-top: 5px;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .right {
      width: 50%;
      text-align: left;
      padding-left: 40px;
      -webkit-animation: slide-right 0.5s
        cubic-bezier(0.645, 0.045, 0.355, 1) 1.65s both;
      animation: slide-right 0.5s
        cubic-bezier(0.645, 0.045, 0.355, 1) 1.65s both;

      .date {
        margin-top: 10px;
        font-weight: 100;
        font-size: 20px;
      }

      .place {
        color: #2e1c04;
        font-weight: 500;
        margin-top: 5px;
        font-size: 20px;
        text-shadow: 0.5px 0.5px rgba(255, 166, 0, 0.3);
      }
    }
  }

  @media (max-width: 1024px) {
    margin-right: 50px;

    li {
      &:after {
        display: none;
      }

      flex-direction: column-reverse;

      .left {
        text-align: right;
        width: 100%;
        padding-right: 0;
        -webkit-animation: slide-left 0.5s
          cubic-bezier(0.645, 0.045, 0.355, 1) 1.4s both;
        animation: slide-left 0.5s
          cubic-bezier(0.645, 0.045, 0.355, 1) 1.4s both;

        .title {
          font-size: 26px;
        }
      }

      .right {
        text-align: right;
        width: 100%;
        padding-left: 0;
        -webkit-animation: slide-left 0.5s
          cubic-bezier(0.645, 0.045, 0.355, 1) 1.1s both;
        animation: slide-left 0.5s
          cubic-bezier(0.645, 0.045, 0.355, 1) 1.1s both;

        &:before {
          width: 16px;
          height: 16px;
          content: "";
          display: block;
          border-radius: 100%;
          background: #805a29;
          border: 1px solid white;
          margin-top: 15px;
          float: right;
          position: relative;
          left: 45px;
          top: 52px;
          margin-left: -18px;
          -webkit-animation: slide-top 0.4s
            cubic-bezier(0.645, 0.045, 0.355, 1) 1.6s both;
          animation: slide-top 0.4s
            cubic-bezier(0.645, 0.045, 0.355, 1) 1.6s both;
        }

        .place {
          color: #8fcbe6;
          font-weight: 100;
          margin-top: 5px;
          font-size: 16px;
          text-shadow: 0.5px 0.5px rgba(0, 0, 0, 0.5);
        }

        .date {
          font-size: 16px;
        }
      }
    }
  }

  @media (min-width: 767px) and (max-width: 1025px) {
    li {
      .right {
        &:before {
          left: 42px;
        }
      }
    }
  }
}

@import "../globals/index.scss";

.socials {
  list-style-type: none;
  padding: 0;

  li {
    background: #194b64;
    border: 1px solid white;
    height: 54px;
    width: 54px;
    margin: 10px auto;
    border-radius: 100%;
    cursor: pointer;

    &.button {
      background-size: 28px;
      background-position: center center;
      background-repeat: no-repeat;

      &:hover {
        background-color: #3f87aa;
        box-shadow: 0px 2px rgba(0, 0, 0, 0.5);
      }
    }

    &.medium {
      background-image: url(../assets/medium.png);
    }

    &.linkedin {
      background-image: url(../assets/linkedin.png);
    }

    &.github {
      background-image: url(../assets/github.png);
    }

    &.email {
      background-image: url(../assets/email.png);
    }

    &:nth-child(1) {
      -webkit-animation: slide-top 0.4s
        cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s both;
      animation: slide-top 0.4s
        cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s both;
    }

    &:nth-child(2) {
      -webkit-animation: slide-top 0.4s
        cubic-bezier(0.645, 0.045, 0.355, 1) 0.4s both;
      animation: slide-top 0.4s
        cubic-bezier(0.645, 0.045, 0.355, 1) 0.4s both;
    }

    &:nth-child(3) {
      -webkit-animation: slide-top 0.4s
        cubic-bezier(0.645, 0.045, 0.355, 1) 0.6s both;
      animation: slide-top 0.4s
        cubic-bezier(0.645, 0.045, 0.355, 1) 0.6s both;
    }

    &:nth-child(4) {
      -webkit-animation: slide-top 0.4s
        cubic-bezier(0.645, 0.045, 0.355, 1) 0.8s both;
      animation: slide-top 0.4s
        cubic-bezier(0.645, 0.045, 0.355, 1) 0.8s both;
    }
  }

  @media (max-width: 1024px) {
    margin-bottom: 0;
    margin-right: 3px;

    li {
      height: 32px;
      width: 32px;
      margin-right: 10px;

      &.button {
        background-size: 16px;
      }
    }
  }

  @media (min-width: 767px) and (max-width: 1025px) {
    margin-right: 7px;
  }
}

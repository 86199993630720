@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@100;200;300;400;500;600&display=swap");
@import "./src/globals/index.scss";

html,
body,
.App,
#root {
  height: 100%;
  font-family: "Source Sans Pro", "Open Sans", "Helvetica", sans-serif;

  @media (max-width: 1024px) {
    max-width: 100%;
  }
}

h2 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-weight: 100;
  font-size: 48px;
  line-height: 60px;
  text-transform: uppercase;
  font-family: "Source Sans Pro", "Open Sans", "Helvetica", sans-serif;
  word-wrap: normal;
  word-spacing: 20px;
  text-align: center;
  color: #5a5a5a;

  @media (max-width: 1024px) {
    word-spacing: 0px;
    text-indent: 0px;
    text-align: left;
    line-height: 100%;
    padding: 0 5%;
    font-size: 52px;
    width: 100%;
  }

  @media (max-width: 374px) {
    font-size: 32px;
  }

  .portfolioSpan {
    color: #9b9b9b;
  }
}

header {
  @media (max-width: 1024px) {
    display: flex;
    padding-right: 14px;
  }
}

.Portfolio {
  padding: 20px;
}

.carousel {
  display: flex;
  margin: 40px auto 0;
  width: 500px;

  @media (max-width: 1024px) {
    width: 300px;
    .forward,
    .back {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  .logo {
    width: 250px;
    height: 100px;
    background-image: url(../../assets/logos.png);
    background-size: 250px;

    @media (max-width: 1024px) {
      max-width: 120x;
    }
  }

  .forward,
  .back {
    cursor: pointer;
  }

  .forward {
    width: 0;
    height: 0;
    margin-top: 35px;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    border-left: 16px solid rgb(216, 216, 216);
    margin-left: 100px;

    &:hover {
      border-left: 16px solid rgb(41, 135, 223);
    }
  }

  .back {
    width: 0;
    height: 0;
    margin-top: 35px;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    border-right: 16px solid rgb(216, 216, 216);
    margin-right: 100px;

    &:hover {
      border-right: 16px solid rgb(41, 135, 223);
    }
  }
}

.content {
  margin-top: 40px;
  display: flex;
  flex-direction: column;

  img {
    align-self: center;
    max-width: 1000px;

    @media (max-width: 1024px) {
      max-width: 100%;
    }

    @media (max-width: 374px) {
      max-width: 100%;
    }
  }
}

.content-text {
  font-size: 20px;
  color:rgb(148, 148, 148);
  padding: 0px 0 30px;
  text-transform: lowercase;
  font-weight: 100;
  text-align: left;
  width: 1000px;
  margin: 0 auto;

  @media (max-width: 1024px) {
    max-width: 100%;
  }

  @media (max-width: 374px) {
    max-width: 100%;
  }

  span {
    font-weight: 400;
  }
}